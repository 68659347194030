<template>
  <v-container style="overflow: hidden;" class="bg-glass-effect ma-0 pa-0" fluid>

      <BackArrow v-if="isLoadCards" style="z-index: 100"/>

      <v-row v-if="!isLoadCards" justify="center" style="height: 100%">
        <v-col cols="6" align="center" align-self="center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-else class="px-0 mx-0 my-0">
        <v-card elevation="0" width="100%" style="background-color: transparent;position: absolute; top: 0;left: 0; z-index: 99">
          <v-col class="pb-0 pt-13 mt-1 mb-0 mx-3" cols="10">
            <h2 class="px-0 mx-0" style="color: #292867">Carteirinhas</h2>
          </v-col>

          <v-col class="benefit-name pb-8 pt-0 mt-1 mx-3" cols="10">
            <p class="pa-0 ma-0">{{benefit}} - Operadora {{carrier.name}}</p>
          </v-col>
        </v-card>

      <v-col class=" pa-0 ma-0" cols="12">
        <div style="margin-top: 22vh; height: 71vh ; overflow-y: scroll;">
          <div
              style="padding-top: 95px"
              class="rounded-xl my-0 mx-2"
              v-for="(card, index) in cards"
              :key="index"
          >
            <v-card elevation="8" id="cardVertical" class="my-0 py-0 item-card" width="60vh" height="75vw" style="margin-top: 300px ; border-radius: 30px !important" :style="style">
              <v-card-text class=" py-0 my-0">
                <v-row class="px-3">
                  <v-col class="px-0 py-6 ma-2" cols="12">
                    <div class="row" style="" v-html="card.cardProcessTemplate ? card.cardProcessTemplate : cardTemplateIfNotExist"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card><br>
          </div>
        </div>
      </v-col>
        <v-card elevation="0" width="100%" style="background-color: transparent;position: fixed; top: 94vh; z-index: 9999">
        <v-col class="pt-0" cols="11" align="center">
          <v-btn class="btn-site pl-12 pr-6" depressed rounded color="#D6DAFF" @click="openUrl()">
            Ir para o site
            <img class="ml-6" style="width: 28px; height: 23px" src="/img/icons/common/right-arrow.svg"/>
          </v-btn>
        </v-col>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import BackArrow from '@/components/covid/back-arrow/index'
  export default {
    name: "walletDetails",
    data: () => ({
      cards: [],
      cardTemplateIfNotExist: '<p style=\'text-align:center; color:white\'>Não foi possível carregar os dados da carteirinha</p>',
      carrier: {
        name: ''
      },
      benefit: '',
      appAddress: '',
      appAddressIos: '',
      isLoadCards: false,
      style: { background: 'linear-gradient(135deg, rgba(41,40,103,1) 43%, rgba(129,128,170,1) 100%)', color: 'white' }
    }),
    components: {
      BackArrow
    },
    created () {
      this.style = this.$route.params.style ? this.$route.params.style : this.style
      this.cards = this.$route.params.cards ? this.$route.params.cards : this.cards
      if(this.cards.length != 0){
        this.carrier.name = this.cards[0].carrierName ? this.cards[0].carrierName : this.carrier.name
        this.benefit = this.cards[0].benefitName ? this.cards[0].benefitName : this.benefit
        this.appAddress = this.cards[0].carrierAppAddress ? this.cards[0].carrierAppAddress : ''
        this.appAddressIos = this.cards[0].carrierAppAddressIos ? this.cards[0].carrierAppAddressIos : ''
        this.isLoadCards = true
      } else {
        this.$router.push({name: "wallet"})
      }
    },
    methods: {
      openUrl () {
        let link = ''
        try{
          link = this.$util.getUrlApp(JSON.parse(this.appAddress))
        } catch(e){
          if (this.$util.isIosMobile()) {
            link = this.appAddressIos
          } else {
            link = this.appAddress
          }
        } finally {
          window.location.href = link
        }
      }
    }
  }
</script>

<style scoped>
#cardVertical{
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
}

/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.8) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
  width: 100vw;
}

.benefit-name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #868698;
  font-weight: bold;
}

.btn-site {
  text-transform: capitalize;
  color: #4A499B;
  font-weight: bold;
}

</style>


